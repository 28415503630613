import React, { useState, useEffect } from 'react';
import axios from '../../util/axiosConfig';
import { connect } from 'react-redux';

export const MiscDataContext = React.createContext({});

const mapStateToProps = ({ settings, auth }) => {
  return { settings, auth };
};

export const MiscProvider = connect(mapStateToProps)(props => {
  const [privileges, setPrivileges] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [pendingReports, setPendingReports] = useState(0); // TODO
  const [generalData, setGeneralData] = useState({});

  const getData = () => {
    getGeneralData();
    getDashboardData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getDashboardData = () => {
    axios
      .get('/misc/dashboard')
      .then(res => {
        setDashboardData(res.data);
        // console.log(res.data)
      })
      .catch(err => {
        // console.log(err.response)
      });
  };
  const getGeneralData = () => {
    axios
      .get('/misc')
      .then(res => {
        setGeneralData(res.data);
        // console.log(res.data)
      })
      .catch(err => {
        // console.log(err.response)
      });
  };

  return (
    <MiscDataContext.Provider
      value={{
        dashboardData,
        privileges,
        getData,
        pendingReports,
        ...generalData,
      }}
    >
      {props.children}
    </MiscDataContext.Provider>
  );
});

export const MiscConsumer = MiscDataContext.Consumer;
