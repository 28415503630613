import React, { useState, Component } from 'react';
import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';
import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import { userSignOut } from '../actions';
import IntlMessages from './IntlMessages';
import { WHITE_COLOR } from '../constants/ThemeColors';
import placeholder from '../assets/images/placeholder.png';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const Header = ({ user, currentTime, signOut, history }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setDropdownOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setDropdownOpen(false);
  };

  return (
    <div
      className={'app-wrapper d-flex pb-3 pt-3'}
      style={{
        backgroundColor: WHITE_COLOR,
        borderBottom: '0.5px solid',
        borderColor: '#c6c6c6',
        zIndex: 1,
        color: '#383838',
      }}
    >
      <div style={{ flexGrow: 1 }} />

      <div
        className={'d-flex m-0 header-user d-flex flex-row align-items-center'}
      >
        <p className={'mt-3 font-weight-light mr-3'}>
          <IntlMessages
            id={moment().hours() < 13 ? 'goodMorning' : 'goodEvening'}
          />
          , <span className={'font-weight-normal'}>{user && user.name}</span>!
        </p>
        <Avatar
          alt="profile"
          src={placeholder}
          className={'mt-auto mb-auto user-header-photo cursor-pointer'}
          onClick={handleClick}
        />
        <div className="user-detail">
          <h4 className="user-name" onClick={handleClick}>
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle text-muted" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={anchorEl}
          open={dropdownOpen}
          onClose={handleRequestClose}
          PaperProps={{
            style: {
              width: 136,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <MenuItem
            onClick={() => {
              handleRequestClose();
              // history.push('/app/account');
            }}
          >
            <i className="zmdi zmdi-account-circle zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.myAccount" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleRequestClose();
              signOut();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

const asyncComponent = (importComponent) => {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.showHeader = !this.props.hideHeader;
      this.state = {
        component: null,
        ...(this.showHeader && { currentTime: moment().format('HH:mm:ss') }),
      };
    }

    componentWillMount() {
      Nprogress.start();
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    async componentDidMount() {
      this.mounted = true;
      const { default: Component } = await importComponent();
      Nprogress.done();
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />,
        });
      }
    }

    render() {
      const Component = this.state.component || <div />;
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {this.showHeader && (
            <Header
              currentTime={this.state.currentTime}
              user={this.props.user}
              signOut={this.props.userSignOut}
            />
          )}
          {Component}
        </ReactPlaceholder>
      );
    }
  }

  const mapStateToProps = ({ auth }) => {
    const { user } = auth;
    return { user };
  };

  return connect(mapStateToProps, { userSignOut })(AsyncFunc);
};

export default asyncComponent;
