import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../components/Header/index';
import Sidebar from '../containers/SideNav/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from '../constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from '../components/TopNav';
import { RoleConsumer, RoleProvider } from '../app/contexts/RoleContext';
import { PROVIDER, SUPER_ADMIN } from '../constants/privileges';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import customTheme from '../containers/themes/customTheme';
import '../styles/custom.css';
import { MiscProvider } from './contexts/DataContext';

const AdminRoute = ({ component: Component, ...rest }) => (
  <RoleConsumer>
    {(user) => (
      <Route
        render={(props) => {
          props = { ...rest, ...props };
          return user ? <Component {...props} /> : null;
        }}
        {...rest}
      />
    )}
  </RoleConsumer>
);

const App = (props) => {
  const { match, drawerType, navigationStyle, horizontalNavPosition } = props;
  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'fixed-drawer'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? 'collapsible-drawer'
    : 'mini-drawer';

  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add('ios-mobile-view-height');
  } else if (document.body.classList.contains('ios-mobile-view-height')) {
    document.body.classList.remove('ios-mobile-view-height');
  }
  const applyTheme = createMuiTheme(customTheme());
  return (
    <MiscProvider>
      <ThemeProvider theme={applyTheme}>
        <div className={`app-container ${drawerStyle}`}>
          <Sidebar />
          <div className="app-main-container">
            <div
              className={`d-block d-xl-none app-header ${
                navigationStyle === HORIZONTAL_NAVIGATION
                  ? 'app-header-horizontal'
                  : ''
              }`}
            >
              {navigationStyle === HORIZONTAL_NAVIGATION &&
                horizontalNavPosition === ABOVE_THE_HEADER && (
                  <TopNav styleName="app-top-header" />
                )}
              <Header />
              {navigationStyle === HORIZONTAL_NAVIGATION &&
                horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
            </div>

            <RoleConsumer>{(user) => <Sidebar user={user} />}</RoleConsumer>

            <main className="app-main-content-wrapper">
              <div className="app-main-content">
                <Switch>
                  <Route
                    path={`/index`}
                    component={asyncComponent(() =>
                      import('./routes/MainPage')
                    )}
                  />

                  <AdminRoute
                    path={`/users`}
                    exact
                    showHeader
                    component={asyncComponent(() => import('./routes/Users'))}
                  />

                  <AdminRoute
                    path={`/ads`}
                    exact
                    showHeader
                    component={asyncComponent(() => import('./routes/Ads'))}
                  />

                  <AdminRoute
                    path={`/ads/:id`}
                    exact
                    showHeader
                    component={asyncComponent(() =>
                      import('./routes/Ads/Components/AdDetails')
                    )}
                  />

                  <AdminRoute
                    path={`/reports`}
                    exact
                    showHeader
                    component={asyncComponent(() => import('./routes/Reports'))}
                  />

                  <AdminRoute
                    path={`/rewards`}
                    exact
                    showHeader
                    component={asyncComponent(() => import('./routes/Rewards'))}
                  />

                  <AdminRoute
                    path={`/rewards/redeems`}
                    exact
                    showHeader
                    component={asyncComponent(() =>
                      import('./routes/RewardRedeems')
                    )}
                  />

                  <AdminRoute
                    path={`/pet-shelters`}
                    exact
                    showHeader
                    component={asyncComponent(() =>
                      import('./routes/PetShelters')
                    )}
                  />

                  <Route
                    path={`${match.url}/`}
                    exact
                    component={asyncComponent(() =>
                      import('./routes/MainPage')
                    )}
                  />

                  <Route
                    component={asyncComponent(() =>
                      import('../components/Error404')
                    )}
                  />
                </Switch>
              </div>
            </main>
          </div>
        </div>
      </ThemeProvider>
    </MiscProvider>
  );
};

const mapStateToProps = ({ settings, auth }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { business } = auth;
  return { drawerType, navigationStyle, horizontalNavPosition, business };
};
export default withRouter(connect(mapStateToProps)(App));
