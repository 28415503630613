import {
    BRAND_COLOR,
    INFO_COLOR,
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    SUCCESS_COLOR,
    WHITE_COLOR
} from "../../constants/ThemeColors";

const themeConfig = (props = {}) =>
    ({
        palette: {
            common:{
                black: '#000000',
                white: '#ffffff'
            },
            type: 'light',
            types: {
                light: {
                    background: {
                        paper: '#ffffff',
                        default: '#ffffff',
                        appBar: '#ffffff',
                        contentFrame: '#ffffff',
                        chip: '#ffffff',
                        avatar: '#ffffff'
                    }
                }
            },
            primary: {
                light: PRIMARY_COLOR,
                main: PRIMARY_COLOR,
                dark: PRIMARY_COLOR,
                contrastText: '#fff',
            },
            secondary: {
                light: SECONDARY_COLOR,
                main: SECONDARY_COLOR,
                dark: SECONDARY_COLOR,
                contrastText: '#fff'
            },
            background: {
                paper: "#fff",
                default: props.backgroundColor ? props.backgroundColor : null
            }
        },
        typography: {
            button: {
                fontWeight: 400,
                textTransform: 'capitalize',
                useNextVariants: true
            },
            fontFamily: [
                'PeTrace',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        props: {
            MuiButton: {
                root: {
                    fontSize: '5rem',

                },
            },
        },



        overrides: {
            MuiButton: {
                root: {
                    backgroundColor: PRIMARY_COLOR,
                    marginTop: 10,
                    borderRadius: 5,
                    // fontWeight: 'bold',
                    fontSize: '16px',
                    height: 50,
                    color: WHITE_COLOR,
                    '&:hover': {
                        backgroundColor: INFO_COLOR,
                    },
                    textTransform: 'none',
                },
                text: {
                    padding: '8px 18px'
                },
                sizeSmall: {
                    padding: '8px 18px'
                },
            },

            MuiInput: {
                root: {
                    [`& fieldset`]: {
                        borderRadius: 5,
                    },
                    borderRadius: 5
                }
            },

            MuiOutlinedInput: {
                root: {
                    [`& fieldset`]: {
                        borderRadius: 5,
                    },
                    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                        borderColor: PRIMARY_COLOR,
                        '@media (hover: none)': {
                            borderColor: '#CFCFCF',
                        },
                    },
                    '&$focused $notchedOutline': {
                        borderColor: '#383838',
                        borderWidth: 1,
                    },
                }

            },
            MuiTextField: {
                root: {
                    [`& fieldset`]: {
                        borderRadius: 4,
                        color: 'black'
                    },
                    borderColor: '#CFCFCF',
                    // backgroundColor: '#F8F8F8',
                    borderRadius: 4,
                    marginTop: 5,
                    '& label.Mui-focused': {
                        color: '#383838',
                    },
                }
            },
            MuiFormHelperText: {
                contained: {
                    marginLeft: 0
                }
            },
            MuiSwitch: {
                root: {
                    width: 70,
                    height: 48,
                    borderRadius: 0,
                    marginLeft: 2,
                    marginRight: 8,
                    overflow: 'visible'
                },
                switchBase: {
                    root: {
                        borderRadius: 3,
                        zIndex: 2000

                    },
                    '&$checked': {
                        color: WHITE_COLOR,
                        '& + $track': {
                            backgroundColor: '#52d869',
                            opacity: 1,
                            borderRadius: 3,
                        },
                    },
                    '&$focusVisible $thumb': {
                        color: '#52d869',
                        border: '6px solid #fff',
                    },
                    '&$checked + $track': {
                        borderRadius: 3,
                    },
                },
                track: {
                    borderRadius: 3
                }
            },

            MuiCssBaseline: {
                "@global": {
                    body: {...props.background}
                }
            }

        }

    });

export default themeConfig;

