import React, {useState} from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/animations/dog_walking'
import IntlMessages from "../../util/IntlMessages";

const AppLoader = props => {

  const [paused, setPaused] = useState(false);
  const [stopped, setStopped] = useState(false);
  const [direction, setDirection] = useState(1);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };

  return (

      <div style={styles.main}>

        <Lottie options={defaultOptions}
                height={400}
                width={400}
                speed={1.75}
                isStopped={stopped}
                isPaused={paused}
                direction={direction}
                eventListeners={[
                  {
                    eventName: 'loopComplete',
                    callback: () => {
                      setDirection(direction === -1 ? -1 : 1);
                    },
                  },
                ]}
        />

      <IntlMessages id={'loadingData'}/>
      </div>
  )
};

export default AppLoader;

const styles = {
  main: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '24vh',
    textAlign: 'center'
  }
};
