import React from 'react';
import Button from '../components/Button/Button';
import Input from '../components/Input/Input';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import IntlMessages from '../util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import './SingIn.css';
import { PRIMARY_COLOR } from '../constants/ThemeColors';
import Notification from '../components/Notification/Notification';
import axios from '../util/axiosConfig';
import customTheme from './themes/customTheme';
import logoGreen from '../assets/images/petrace-sm.svg';
import backgroundShape from '../assets/images/background-shape.png';

class ResetPassword extends React.Component {
  state = {
    password: '',
    passwordConfirmation: '',
    isValid: false,
    validations: {
      password: true,
      passwordConfirmation: true,
    },
    showNotification: false,
    loading: true,
    secondStep: false,
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      this.props.hideMessage();
    }
  }

  componentDidMount() {
    if (!this.props.match.params.token) {
      this.props.history.push('/');
    }
    this.setState({
      loading: false,
    });
  }

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    }
  };

  validate = (name, value) => {
    switch (name) {
      case 'password':
      case 'passwordConfirmation':
        return value.length > 5;
      default:
        return true;
    }
  };

  handleChange = (name) => (event) => {
    let form = { ...this.state };

    form[name] = event.target.value;

    let validations = {
      ...this.state.validations,
    };

    validations[name] = this.validate(name, form[name]);

    let isValid = true;

    for (let value of Object.entries(form)) {
      isValid = isValid && this.validate(value[0], value[1]);
      if (!isValid) {
        break;
      }
    }

    if (form.password !== form.passwordConfirmation) isValid = false;

    this.setState({
      ...form,
      validations,
      isValid,
    });
  };

  handleSubmit = () => {
    if (this.state.password && this.state.passwordConfirmation) {
      this.setState({ loading: true });

      axios
        .post('/auth/resetpwd/set', {
          token: this.props.match.params.token,
          password: this.state.password,
        })
        .then((res) => {
          this.setState({ loading: false, secondStep: true });
        })
        .catch((err) => {
          this.showNotification();
          this.setState({ loading: false, error: true });
        });
    }
  };

  showNotification = () => {
    this.setState({ showMessage: true }, () =>
      this.setState({ showMessage: false })
    );
  };

  showMessage = () => this.setState({ showNotification: true });

  render() {
    const {
      passwordConfirmation,
      password,
      isValid,
      validations,
      showMessage,
      loading,
      secondStep,
    } = this.state;
    const applyTheme = createMuiTheme(
      customTheme({
        background: {
          backgroundImage: 'url(' + backgroundShape + ')',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundPosition: '15vw -16vh',
          backgroundSize: 'cover',
        },
      })
    );

    return (
      <MuiThemeProvider theme={applyTheme}>
        <CssBaseline />

        {!loading ? (
          <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div
              className="app-login-main-content"
              style={{ borderRadius: 5, height: 'auto', width: '550px' }}
            >
              <div
                className="app-login-content"
                style={{ width: '100%', height: '' }}
              >
                <div className="app-login-header mb-4">
                  <div className="imgContainer">
                    <img src={logoGreen} alt="logo1" className="logo" />
                  </div>
                  {!secondStep ? (
                    <h1 style={{ fontWeight: 'bold' }}>
                      <IntlMessages id="newPasswordTitle" />
                    </h1>
                  ) : (
                    <h1 style={{ fontWeight: 'bold' }}>
                      <IntlMessages id="newPasswordThanks" />
                    </h1>
                  )}
                </div>

                <div className="app-login-form">
                  {!secondStep ? (
                    <>
                      <form>
                        <fieldset>
                          <Input
                            value={password}
                            type="password"
                            onChange={this.handleChange('password')}
                            label={<IntlMessages id="appModule.password" />}
                            fullWidth
                            helperText={
                              validations.password ? (
                                ''
                              ) : (
                                <IntlMessages id="passwordError" />
                              )
                            }
                            error={!validations.password}
                            margin="normal"
                            className="mt-0 mb-2"
                            variant="outlined"
                            onKeyDown={this.onKeyDown}
                          />

                          <Input
                            value={passwordConfirmation}
                            type="password"
                            label={<IntlMessages id="confirmPassword" />}
                            fullWidth
                            helperText={
                              validations.passwordConfirmation ? (
                                ''
                              ) : (
                                <IntlMessages id="passwordError" />
                              )
                            }
                            error={!validations.passwordConfirmation}
                            onChange={this.handleChange('passwordConfirmation')}
                            margin="normal"
                            variant="outlined"
                            onKeyDown={this.onKeyDown}
                          />

                          <div
                            className={'row ml-1 mb-3'}
                            style={
                              isValid
                                ? { display: 'none' }
                                : { display: 'block' }
                            }
                          >
                            {<IntlMessages id="samePasswords" />}
                          </div>

                          <div className="mb-3 d-flex align-items-center justify-content-between">
                            <Button
                              fullWidth
                              disabled={!isValid}
                              bg={PRIMARY_COLOR}
                              className={'text-white'}
                              onClick={this.handleSubmit}
                            >
                              <IntlMessages id="newPassword" />
                            </Button>
                          </div>
                        </fieldset>
                      </form>
                    </>
                  ) : (
                    <>
                      <div className="mb-3 mt-6 d-flex align-items-center justify-content-between">
                        <Button
                          fullWidth
                          bg={PRIMARY_COLOR}
                          className={'text-white'}
                          onClick={() => (window.location = 'petrace://')}
                        >
                          <IntlMessages id="openPeTraceApp" />
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="loader-view  ml-auto mr-auto">
            <CircularProgress color="secondary" />
          </div>
        )}

        <Notification
          showMessage={showMessage}
          title={'Error'}
          message={
            'Pruébelo más tarde o vuelva a solicitar el cambio de contraseña'
          }
          type={'danger'}
        />
      </MuiThemeProvider>
    );
  }
}

export default ResetPassword;

const styles = {
  link: {
    color: '#00000060',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  linkContainer: {
    marginTop: '5%',
  },
};
