import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";


const CssTextField = withStyles({
 root: {
  }
})(TextField);

const Input = props => (
    <CssTextField {...props} />
);

// const Input = props => {
//
//   const ThemeInput = withStyles(theme => ({
//     root: {
//       ...props.bg && {backgroundColor: props.bg},
//       '&:hover': {
//         backgroundColor: '#deb381',
//       },
//     },
//   }))(MuiInput);
//
//   return <ThemeInput {...props} />;
//
// };


export default Input;
