import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Button from '../../components/Button/Button';
import SidenavContent from './SidenavContent';
import UserInfo from '../../components/UserInfo';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from '../../constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from '../../actions/Setting';
import IntlMessages from '../../util/IntlMessages';
import ModalCodes from '../../components/Modal/ModalCodes';

class SideNav extends React.PureComponent {
  state = {
    modalOpen: false,
  };

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth);
    });
  }

  handleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

  render() {
    const { navCollapsed, drawerType, width, navigationStyle, user, business } =
      this.props;
    let drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-xl-flex'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? ''
      : 'd-flex';
    let type = 'permanent';
    if (
      drawerType.includes(COLLAPSED_DRAWER) ||
      (drawerType.includes(FIXED_DRAWER) && width < 1200)
    ) {
      type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = '';
      type = 'temporary';
    }
    return (
      <div
        className={`app-sidebar d-none ${drawerStyle}`}
        style={{ zIndex: 15 }}
      >
        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes('temporary') ? navCollapsed : true}
          onClose={this.onToggleCollapsedNav}
          classes={{
            paper: 'side-nav',
          }}
        >
          <UserInfo />
          <SidenavContent user={{ ...this.props.user }} />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { navCollapsed, drawerType, width, navigationStyle, isAdminView } =
    settings;
  const { business } = auth;
  return {
    navCollapsed,
    drawerType,
    width,
    navigationStyle,
    isAdminView,
    business,
  };
};

export default withRouter(
  connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(SideNav)
);
