import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import Button from '../Button/Button';
import Typography from '@material-ui/core/Typography';
import Modal, { ModalTitle, ModalActions, ModalContent } from './Modal';
import IntlMessages from '../../util/IntlMessages';
import { SECONDARY_COLOR } from '../../constants/ThemeColors';
import QRcode from 'qrcode.react';

moment.locale('es');

const ModalCodes = (props) => {
  const [seeCode, setSeeCode] = new useState(false);

  const handleBtn = () => setSeeCode(!seeCode);

  return (
    <>
      <Modal
        open={props.modalOpen}
        onClose={props.onClose}
        maxWidth={'md'}
        fullWidth
        PaperProps={{
          style: { borderRadius: 0 },
        }}
      >
        <ModalTitle disableTypography>
          <Typography variant={'h6'} className={'font-weight-bold'}>
            <IntlMessages id="modalTitle.seeCodes" />
          </Typography>
        </ModalTitle>
        <ModalContent>
          <small>
            <IntlMessages id="modalCodesDescription" />
          </small>
          {props.business && props.business.qrCodes ? (
            <div className={'pl-3 pr-3 mt-2 mb-3 '}>
              <div className={'row mt-5 mb-5'}>
                <div
                  className={'col-6 text-center'}
                  style={{ borderRight: 'solid .5px lightgray' }}
                >
                  <QRcode
                    value={
                      props.business.qrCodes.filter(
                        (c) => (c.type = 'check_in')
                      ).length &&
                      props.business.qrCodes.filter(
                        (c) => (c.type = 'check_in')
                      )[0].code
                    }
                    size={window.innerWidth < 600 ? 64 : 320}
                    bgColor={'#ffffff'}
                    fgColor={'#000000'}
                    level={'L'}
                    includeMargin={false}
                    renderAs={'svg'}
                    style={
                      seeCode
                        ? { filter: 'blur(12px)' }
                        : { filter: 'blur(0px)' }
                    }
                  />
                </div>

                <div className={'col-6 text-center'}>
                  <QRcode
                    value={
                      props.business.qrCodes.filter(
                        (c) => (c.type = 'check_out')
                      ).length &&
                      props.business.qrCodes.filter(
                        (c) => (c.type = 'check_out')
                      )[0].code
                    }
                    size={window.innerWidth < 600 ? 64 : 320}
                    bgColor={'#ffffff'}
                    fgColor={'#000000'}
                    level={'L'}
                    includeMargin={false}
                    renderAs={'svg'}
                    style={
                      !seeCode
                        ? { filter: 'blur(12px)' }
                        : { filter: 'blur(0px)' }
                    }
                  />
                </div>
              </div>

              <div className={'row'}>
                <div className={'col-6 text-center'}>
                  <h2
                    className={''}
                    style={
                      !seeCode
                        ? {
                            textAlign: 'center',
                            fontWeight: '700',
                            color: 'black',
                          }
                        : {
                            textAlign: 'center',
                            fontWeight: '700',
                            color: '#b5b5b5',
                          }
                    }
                  >
                    <IntlMessages id="checkIn" />
                  </h2>
                  {!seeCode && (
                    <h3>
                      {props.business.qrCodes.filter(
                        (c) => (c.type = 'check_in')
                      ).length &&
                        props.business.qrCodes.filter(
                          (c) => (c.type = 'check_in')
                        )[0].code}
                      <u>IN</u>
                    </h3>
                  )}
                  <Button
                    bg={SECONDARY_COLOR}
                    onClick={handleBtn}
                    style={seeCode ? {} : { display: 'none' }}
                  >
                    <IntlMessages id="seeCode" />
                  </Button>
                </div>
                <div className={'col-6 text-center'}>
                  <h2
                    className={''}
                    style={
                      !seeCode
                        ? {
                            textAlign: 'center',
                            fontWeight: '700',
                            color: '#b5b5b5',
                          }
                        : {
                            textAlign: 'center',
                            fontWeight: '700',
                            color: 'black',
                          }
                    }
                  >
                    <IntlMessages id="checkOut" />
                  </h2>
                  {seeCode && (
                    <h3>
                      {props.business.qrCodes.filter(
                        (c) => (c.type = 'check_out')
                      ).length &&
                        props.business.qrCodes.filter(
                          (c) => (c.type = 'check_out')
                        )[0].code}
                      <u>OT</u>
                    </h3>
                  )}
                  <Button
                    bg={SECONDARY_COLOR}
                    onClick={handleBtn}
                    style={!seeCode ? {} : { display: 'none' }}
                  >
                    <IntlMessages id="seeCode" />
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div>No tienes códigos</div>
          )}
        </ModalContent>
        <ModalActions>
          <span>
            <strong>
              <IntlMessages id="needQr" />
            </strong>
          </span>
          <span>
            <IntlMessages id="contactUs" />
          </span>
        </ModalActions>
      </Modal>
    </>
  );
};

export default ModalCodes;
