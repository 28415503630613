import React from 'react';
import MuiButton from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core/styles";
import { INFO_COLOR } from "../../constants/ThemeColors";

const Button = props => {

  const ThemeButton = withStyles(theme => ({
    root: {
    ...props.bg && {backgroundColor: props.bg},
    ...props.clr && {color: props.clr},
      '&:hover': {
        backgroundColor: INFO_COLOR,
      },
      '&:disabled': {
        color: '#C6C6C6',
        cursor: 'not-allowed'
      },
    },
  }))(MuiButton);

  return <ThemeButton {...props} />;

};

export default Button;
