import React, { Component } from 'react';
import './App.scss';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import '../assets/vendors/style';
import customTheme from './themes/customTheme';
import AppLocale from '../lngProvider';
import MainApp from '../app/index';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Recover from './Recover';
import {
  setInitUrl,
  subscribeTokenRefresh,
  getUser,
  hideAuthLoader,
} from '../actions';
import asyncComponent from '../util/asyncComponent';
import { RoleProvider } from '../app/contexts/RoleContext';
import axios from '../util/axiosConfig';
import Notification from '../components/Notification/Notification';
import AppLoader from '../components/AppLoader/AppLoader';
import ResetPassword from './ResetPassword';
import ValidateEmail from './ValidateEmail';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  state = { counter: true };

  componentDidMount() {
    this.props.loader &&
      setTimeout(() => {
        this.setState({ counter: false });
      }, 1500);

    if (this.props.authUser) {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + this.props.authUser;
      this.props.setInitUrl(this.props.history.location.pathname);

      const hasRefreshToken = this.props.refreshToken;
      const { subscribeTokenRefresh } = this.props;

      axios.interceptors.response.use(
        function (config) {
          return config;
        },
        function (error) {
          const { config } = error;

          if (
            config &&
            !config.url.endsWith('refresh') &&
            (error.response.status === 403 || error.response.status === 401)
          ) {
            if (!hasRefreshToken) {
              localStorage.removeItem('user_id');
              localStorage.removeItem('user');
              window.location.href = '/';
              return;
            }

            return new Promise((resolve) => {
              subscribeTokenRefresh((token) => {
                config.headers.Authorization = `Bearer ${token}`;
                resolve(axios(config));
              });
            });
          }
          return Promise.reject(error);
        }
      );

      this.props.getUser();
    } else {
      if (this.props.initURL === '') {
        this.props.setInitUrl('/');
      }
    }
  }

  render() {
    const {
      match,
      location,
      locale,
      authUser,
      initURL,
      user,
      loader,
      showMessage,
      authMessage,
      hideAuthLoader,
    } = this.props;

    if (location.pathname.startsWith('/signin') && authUser) {
      hideAuthLoader();
      return <Redirect to={'/'} />;
    }

    const applyTheme = createMuiTheme(customTheme({}));

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <RoleProvider value={user}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}
            >
              <div className="app-main">
                {!this.props.location.pathname.startsWith('/signin') &&
                this.state.counter ? (
                  <AppLoader />
                ) : (
                  <Switch>
                    <Route path="/signup" component={SignUp} />
                    <Route path="/reset/:token" component={ResetPassword} />
                    <Route path="/recover" component={Recover} />
                    <Route path="/validate/:token" component={ValidateEmail} />
                    <Route path="/signin" exact component={SignIn} />
                    <Route path="/signin/:id" component={SignIn} />
                    <RestrictedRoute
                      path={match.url}
                      authUser={authUser}
                      component={MainApp}
                    />
                    <Route
                      component={asyncComponent(() =>
                        import('../components/Error404')
                      )}
                    />
                  </Switch>
                )}
                <Notification
                  showMessage={showMessage}
                  message={authMessage}
                  type={'danger'}
                />
              </div>
            </IntlProvider>
          </MuiPickersUtilsProvider>
        </RoleProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale } = settings;
  const {
    authUser,
    initURL,
    user,
    refreshToken,
    loader,
    showMessage,
    authMessage,
    hideMessage,
  } = auth;
  return {
    sideNavColor,
    locale,
    authUser,
    initURL,
    user,
    refreshToken,
    loader,
    showMessage,
    authMessage,
    hideMessage,
  };
};

export default connect(mapStateToProps, {
  setInitUrl,
  subscribeTokenRefresh,
  getUser,
  hideAuthLoader,
})(App);
