import React from 'react';
import { connect } from 'react-redux';
import Button from '../components/Button/Button';
import Input from '../components/Input/Input';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from '../util/IntlMessages';
import { hideMessage, showAuthLoader, userSignUp } from '../actions/Auth';
import { createMuiTheme } from '@material-ui/core';
import { BRAND_COLOR } from '../constants/strings';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import customTheme from './themes/customTheme';
import { SUCCESS_COLOR } from '../constants/ThemeColors';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import axios from '../util/axiosConfig';

class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      form: {
        name: '',
        email: '',
        tel: '',
        location: '',
        contact: '',
        description: '',
      },
      nextStep: false,
      isValid: false,
      validations: {
        name: true,
        email: true,
        tel: true,
        location: true,
        contact: true,
      },
      businessTypes: null,
      selectedType: null,
    };
  }

  componentWillMount() {
    axios
      .get('/misc/data')
      .then((res) => {
        this.setState({
          businessTypes: res.data.businessTypes,
          selectedType: res.data.businessTypes[0].id,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  validate = (name, value) => {
    switch (name) {
      case 'name':
      case 'location':
      case 'contact':
        return value && value.length > 1;
      case 'email':
        const pattern =
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return value && pattern.test(value);
      case 'tel':
        const numbers = /^[0-9]*$/;
        return value && numbers.test(value) && value.length === 9;
      default:
        return true;
    }
  };

  handleChange = (name) => (event) => {
    let form = {
      ...this.state.form,
    };

    form[name] = event.target.value;

    let validations = {
      ...this.state.validations,
    };

    validations[name] = this.validate(name, form[name]);

    let isValid = true;

    for (let value of Object.entries(form)) {
      isValid = isValid && this.validate(value[0], value[1]);
      if (!isValid) {
        break;
      }
    }

    this.setState({
      form,
      validations,
      isValid,
    });
  };

  handleOpenSelect = (event) => {
    this.setState({
      selectedType: event.target.value,
    });
  };

  handleSubmit = () => {
    if (!this.state.nextStep) {
      this.setState({
        nextStep: !this.state.nextStep,
      });
    }
  };

  render() {
    const {
      name,
      email,
      tel,
      location,
      contact,
      description,
      nextStep,
      isValid,
      validations,
      businessTypes,
    } = this.state;

    const { showMessage, loader, alertMessage } = this.props;
    const applyTheme = createMuiTheme(
      customTheme({ backgroundColor: BRAND_COLOR })
    );

    return (
      <MuiThemeProvider theme={applyTheme}>
        <CssBaseline />

        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div
            className="app-login-main-content app-login-content"
            style={{ borderRadius: 0, width: '550px' }}
          >
            <div className="">
              <div className="app-login-header" style={styles.title}>
                <img
                  src={require('../assets/images/placeholder.png')}
                  alt="logo1"
                  className="logo"
                  style={styles.logo}
                />
                <h1 style={{ fontWeight: 'bold' }}>
                  {!nextStep ? (
                    <IntlMessages id="signUpTitle" />
                  ) : (
                    <IntlMessages id="signUp2Title" />
                  )}
                </h1>
              </div>

              <div className="app-login-form">
                <form method="post" action="/">
                  {!nextStep ? (
                    <>
                      <Input
                        value={name}
                        type="text"
                        label={<IntlMessages id="nameRegister" />}
                        onChange={this.handleChange('name')}
                        fullWidth
                        error={!validations.name}
                        margin="normal"
                        className="mt-0 mb-2"
                        variant="outlined"
                        required
                      />

                      <Input
                        value={email}
                        type="email"
                        onChange={this.handleChange('email')}
                        label={<IntlMessages id="appModule.email" />}
                        fullWidth
                        helperText={
                          validations.email ? (
                            ''
                          ) : (
                            <IntlMessages id="emailError" />
                          )
                        }
                        error={!validations.email}
                        margin="normal"
                        className="mt-0 mb-2"
                        variant="outlined"
                        required
                      />

                      <Input
                        value={tel}
                        type="text"
                        label={<IntlMessages id="tel" />}
                        onChange={this.handleChange('tel')}
                        fullWidth
                        helperText={
                          validations.tel ? '' : <IntlMessages id="telError" />
                        }
                        error={!validations.tel}
                        margin="normal"
                        className="mt-0 mb-2"
                        variant="outlined"
                        required
                      />

                      <Input
                        value={location}
                        type="text"
                        label={<IntlMessages id="location" />}
                        onChange={this.handleChange('location')}
                        fullWidth
                        error={!validations.location}
                        margin="normal"
                        className="mt-0 mb-2"
                        variant="outlined"
                        required
                      />

                      <Input
                        value={contact}
                        type="text"
                        label={<IntlMessages id="contact" />}
                        onChange={this.handleChange('contact')}
                        fullWidth
                        error={!validations.contact}
                        margin="normal"
                        className="mt-0 mb-2"
                        variant="outlined"
                        required
                      />
                    </>
                  ) : (
                    <>
                      <Input
                        value={description}
                        label={<IntlMessages id="spaceDescription" />}
                        fullWidth
                        onChange={this.handleChange('description')}
                        margin="normal"
                        variant="outlined"
                        multiline="true"
                        rows="4"
                        style={styles.textField}
                        onKeyDown={this.onKeyDown}
                      />
                      <FormControl>
                        <Select
                          value={this.state.selectedType}
                          onChange={this.handleOpenSelect}
                          name={'businessType'}
                          // autoWidth

                          style={{ width: 480 }}
                          className={'mb-2'}
                          input={<OutlinedInput />}
                          children={
                            businessTypes &&
                            businessTypes.map((t) => (
                              <MenuItem value={t.id} key={t.id}>
                                {t.businessTypeTranslations[0].title}
                              </MenuItem>
                            ))
                          }
                        />
                      </FormControl>
                    </>
                  )}

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      fullWidth
                      // disabled={!isValid}
                      bg={SUCCESS_COLOR}
                      onClick={this.handleSubmit}
                    >
                      {nextStep ? (
                        <IntlMessages id="sendRequest" />
                      ) : (
                        <IntlMessages id="appModule.next" />
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && NotificationManager.error(alertMessage)}
          <NotificationContainer />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader,
})(SignUp);

const styles = {
  title: {
    display: 'inline-flex',
  },
  logo: {
    marginRight: 15,
  },
};
