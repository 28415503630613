import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  REFRESH_TOKEN,
  GET_USER,
} from '../constants/ActionTypes';
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  subscribeTokenRefreshSuccess,
  getUserSuccess,
  showAuthLoader,
  hideAuthLoader,
} from '../actions';
import { SUPER_ADMIN, ADMIN } from '../constants/privileges';
import axios from '../util/axiosConfig';

const getUser = async () =>
  await axios
    .get('/user/me')
    .then(async res => {
      if (
        !res.data.privileges.find(p => p.id === SUPER_ADMIN || p.id === ADMIN)
      ) {
        signOut();
        return false;
      }
      localStorage.setItem(
        'user',
        JSON.stringify({
          name: res.data.name || res.data.email || 'User',
          image: res.data.imageId,
        })
      );
      return {
        name: res.data.name || res.data.email || 'User',
        privileges: res.data.privileges,
        image: res.data.imageId,
      };
    })
    .catch(async err => {
      console.log(err);
      if (!(await refreshTokenRequest())) {
        signOut();
      }
      return false;
    });

// const getBusiness = async () => await axios.get('/provider/business')
//   .then (res => {
//     return res.data;
//   })
//   .catch (err => {
//     return err;
//   });

const signInUserWithEmailPasswordRequest = async params =>
  await axios
    .post('/auth/signin', params)
    .then(async response => {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + response.data['access_token'];
      let user = await getUser();
      if (!user) {
        return { message: "You're not allowed to access" };
      }

      let business = null;
      // if (user.privileges.find(p => p.id === PROVIDER)){
      //   business = await getBusiness();
      // }

      console.log(response);
      return {
        user: user,
        token: response.data['access_token'],
        refreshToken: response.data['refresh_token'],
        business: business,
      };
    })
    .catch(error => {
      console.log(error);
      return error;
    });

const refreshTokenRequest = async params =>
  await axios
    .post('/auth/refresh', params)
    .then(async response => {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + response.data['access_token'];

      return {
        token: response.data['access_token'],
        refreshToken: response.data['refresh_token'],
      };
    })
    .catch(error => {
      console.log(error.response);
      return false;
    });

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  const params = new URLSearchParams();
  params.append('email', email);
  params.append('password', password);
  params.append('client', 'Admin dashboard');
  params.append('version', '1.0');
  params.append('pushToken', '');

  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, params);
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      console.log(signInUser);
      localStorage.setItem('user_id', signInUser.token);
      localStorage.setItem(
        'user',
        JSON.stringify({
          name: signInUser.user.name,
          image: signInUser.user.image,
        })
      );
      localStorage.setItem('refreshToken', signInUser.refreshToken);

      yield put(
        userSignInSuccess(
          signInUser.token,
          signInUser.user,
          signInUser.refreshToken,
          signInUser.business
        )
      );
    }
  } catch (error) {
    // console.log(error)
    yield put(showAuthMessage(error));
  }
}

function* refreshToken({ payload }) {
  const cb = payload;
  const params = new URLSearchParams();
  params.append('refresh_token', localStorage.getItem('refreshToken'));
  params.append('client', 'Admin dashboard');
  params.append('version', '1.0');
  try {
    // const refresh = yield call(refreshTokenRequest, params);
    //
    // if (!refresh) {
    //   yield call(signOut);
    // } else {
    //   localStorage.setItem('user_id', refresh.token);
    //   localStorage.setItem('refreshToken', refresh.refreshToken);
    //   cb(refresh.token);
    //   yield put(subscribeTokenRefreshSuccess(refresh.token, refresh.refreshToken));
    // }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    localStorage.removeItem('user_id');
    localStorage.removeItem('user');
    localStorage.removeItem('refreshToken');
    yield put(userSignOutSuccess(signOutUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

const getUserRequest = async () => {
  try {
    let user = await getUser();
    if (!user) {
      signOut();
      return false;
    }
    return { user };
  } catch (error) {
    return false;
  }
};

function* getUserData() {
  try {
    yield put(showAuthLoader());

    const payload = yield call(getUserRequest);

    yield put(getUserSuccess(payload));
    yield put(hideAuthLoader());
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* refreshUserToken() {
  yield takeEvery(REFRESH_TOKEN, refreshToken);
}

export function* getUserInfo() {
  yield takeEvery(GET_USER, getUserData);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    fork(refreshUserToken),
    fork(getUserInfo),
  ]);
}
