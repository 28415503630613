import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Button from '../../components/Button/Button';
import IntlMessages from '../../util/IntlMessages';
import CustomScrollbars from '../../util/CustomScrollbars';
import { connect } from 'react-redux';
import { switchToAdminView } from '../../actions';
import { PRIMARY_COLOR, WHITE_COLOR } from '../../constants/ThemeColors';
import backgroundShape from '../../assets/images/background-shape-sm.png';

class SidenavContent extends Component {
  state = {
    expanded: '',
  };

  handleExpanded = (expanded) => {
    this.setState({
      expanded,
    });
  };

  render() {
    const pathname = this.props.history.location.pathname;
    let { expanded } = this.state;

    return (
      <CustomScrollbars
        className=" scrollbar"
        style={{
          backgroundImage: 'url(' + backgroundShape + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 400,
          backgroundPosition: '-80px 298px',
        }}
      >
        <ul className="nav-menu">
          <li className="nav-header">
            <span className={'text-transform-none'}>
              <IntlMessages id="sidebar.title" />
            </span>
          </li>

          <li className="menu no-arrow">
            <Button bg={WHITE_COLOR} className={'text-dark mt-0'}>
              <NavLink to="/users" className={'pl-0'}>
                <span className="nav-text">
                  <IntlMessages id="sidebar.users" />
                </span>
              </NavLink>
            </Button>
          </li>
          <li className="menu no-arrow">
            <Button bg={WHITE_COLOR} className={'text-dark mt-0'}>
              <NavLink to="/ads" className={'pl-0'}>
                <span className="nav-text">
                  <IntlMessages id="sidebar.ads" />
                </span>
              </NavLink>
            </Button>
          </li>

          <li className="menu no-arrow">
            <Button bg={WHITE_COLOR} className={'text-dark mt-0'}>
              <NavLink to="/reports" className={'pl-0'}>
                <span className="nav-text">
                  <IntlMessages id="sidebar.reports" />
                </span>
              </NavLink>
            </Button>
          </li>

          <li
            className={
              pathname.startsWith('/rewards') || expanded === 'rewards'
                ? 'menu collapse-box open'
                : 'menu collapse-box'
            }
            onClick={() => this.handleExpanded('rewards')}
          >
            <Button bg={WHITE_COLOR} className={'text-dark mt-0'}>
              <span className="nav-text">
                <IntlMessages id="sidebar.rewards" />
              </span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink to="/rewards" exact activeStyle={styles.activeNavLink}>
                  <span className="nav-text">
                    <IntlMessages id="sidebar.rewards" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/rewards/redeems"
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.rewardRedeems" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu no-arrow">
            <Button bg={WHITE_COLOR} className={'text-dark mt-0'}>
              <NavLink to="/pet-shelters" className={'pl-0'}>
                <span className="nav-text">
                  <IntlMessages id="sidebar.petShelters" />
                </span>
              </NavLink>
            </Button>
          </li>

          <li
            className={
              pathname.startsWith('/settings') || expanded === 'settings'
                ? 'menu collapse-box open'
                : 'menu collapse-box'
            }
            onClick={() => this.handleExpanded('settings')}
          >
            <Button bg={WHITE_COLOR} className={'text-dark mt-0'}>
              <span className="nav-text">
                <IntlMessages id="sidebar.settings" />
              </span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink
                  to="/settings/origins"
                  activeStyle={styles.activeNavLink}
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.origins" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>

        <div className={'pl-3 pb-3 fixed-bottom position-absolute opacity-50'}>
          <small
            className={'font-weight-light'}
            style={{ color: 'rgba(56,56,56,0.51)' }}
          >
            PeTrace HUB 1.0
          </small>
        </div>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { business, loader } = auth;
  return { business, loading: loader };
};

export default withRouter(
  connect(mapStateToProps, { switchToAdminView })(SidenavContent)
);

const styles = {
  activeNavLink: {
    fontWeight: '500',
    color: PRIMARY_COLOR,
  },
};
