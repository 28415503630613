import React from 'react';
import Button from '../Button/Button';
import { BaseModal as Modal, ModalContent } from '../Modal/Modal';
import Typography from '@material-ui/core/Typography';
import IntlMessages from '../../util/IntlMessages';
import { ERROR_COLOR, WHITE_COLOR } from '../../constants/ThemeColors';

const CloseNoSave = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      maxWidth={'xs'}
      fullWidth
      PaperProps={{
        style: { borderRadius: 5 },
      }}
    >
      <ModalContent>
        <div className={'pt-3'}>
          <div className={'pt-2 pb-3'}>
            <div>
              <Typography variant="h5" component="h4">
                <IntlMessages id={'modal.closeNoSavingTitle'} />
              </Typography>
              <Typography variant="h6" component="h4">
                <small>
                  <IntlMessages id={'actionCantBeUndone'} />
                </small>
              </Typography>
            </div>
            <div className={'text-right'}>
              <Button
                bg={WHITE_COLOR}
                className={'text-black'}
                style={{ margin: 5 }}
                onClick={props.onClose}
              >
                <IntlMessages id="cancel" />
              </Button>
              <Button
                bg={ERROR_COLOR}
                style={{ margin: 5 }}
                onClick={props.onConfirm}
              >
                <IntlMessages id="close" />
              </Button>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default CloseNoSave;
