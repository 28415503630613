import {
  API_KEY_PRIVATE,
  API_VERSION_PRIVATE,
  BASE_URL
} from "../constants/strings";
import axiosBase from "axios";

axiosBase.defaults.headers.common['Content-Type'] = 'application/json';
axiosBase.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const axios = axiosBase.create({
  baseURL: BASE_URL+API_VERSION_PRIVATE,
  headers: {
    'x-api-key': API_KEY_PRIVATE
  }
});

axios.CancelToken = axiosBase.CancelToken;
axios.isCancel = axiosBase.isCancel;

export default axios;
