import React from 'react';

export const RoleContext = React.createContext();

export const RoleProvider = (props) => {

  return (
    <RoleContext.Provider
      value={props.value}
    >
      {props.children}
    </RoleContext.Provider>
  )
};

export const RoleConsumer = RoleContext.Consumer;
